.card {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 250px;
    margin: 10px;
  }
  
  .card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-image {
    width: 100%;
    height: 140px;
    object-fit: cover;
  }
  
  .card-content {
    padding: 15px;
    text-align: center;
  }
  
  .card-content p {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .card-content a {
    display: inline-block;
    padding: 8px 16px;
    color: rgb(0, 0, 0);
    background-color: #e0eb0e;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .card-content a:hover {
    background-color: #7ced12;
  }
  
  @media (max-width:768px){
    .card-content p {
      font-size: 12px;
      color: #333;
      flex:1;
      text-overflow: ellipsis;
      display: block;
    }

    .card-content {
      display: flex;
      padding: 5px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .card {
      background-color: white;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      max-width: 280px;
      max-height: 270px;
      margin: 10px;
    }
    #card-checkout-link{
      /* display: block; */
      width: 10px;
      height: 12px;
      flex-basis: 20%;
      font-size: 10px;
      text-align: center;
      margin: 10px;
    }
  }