@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
/* body {
  font-family: "Open Sans", sans-serif;
} */

/* styles.css */


html, body {
  font-family: "Open Sans", sans-serif;
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  height: 100%; /* Full height for the body */
  width: 100%; /* Full width for the body */
}

.fullscreen {
  height: 100vh; /* 100% of the viewport height */
  width: 100vw; /* 100% of the viewport width */
}

.app-container{

}




