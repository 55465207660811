.tree {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tree-root {
    font-weight: 800;
    margin-bottom: 20px;

  }
  
  .branch {
    display: flex;
    justify-content: space-around;
    width: 80%;
    position: relative;
  }
  
  .branch::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 50%;
    width: 2px;
    height: 200px;
    background-color: rgb(9, 104, 2);
  }
  
  .company {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  
  .experience {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .experience-node {
    margin: 5px;
    padding: 20px;
    max-width:30vw;
    font-weight: 600;
    min-height: 15vh;
    border: 1px solid #000000;
    border-radius: 5px;
    color: rgba(6, 6, 6, 0.886);
    background-color: rgb(62, 230, 149);
    transition: color 0.5s ease, transform 0.3s ease ,text-decoration-color 0.5s ease,background-color 0.5s ease; 
  }

  .experience-node:hover {
    color: rgba(255, 255, 0, 0.886);
    background-color: rgb(0, 0, 0);
    transform: translate(0px, -5px);
    transform: scale(1.1);
    font-weight: 800;
  }
  
  .company-name {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .job-title {
    font-style: italic;
    margin-bottom: 5px;
  }
  

@media (max-width:768px){
  .tree {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .tree-root {
    font-weight: 800;
    margin-bottom: 20px;

  }
  
  .branch {
    display: flex;
    justify-content: space-around;
    width: 95%;
    position: relative;
  }
  
  
  .company {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

  }
  
  
  .experience {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 10px;
  }
    
  .branch::before {
    display: none;
  }
  .experience-node {
    margin: 0px;
    padding: 10px;
    font-size: xx-small;
    /* max-width:30vw; */
    width: 100% !important;
    font-weight: 800;
    min-height: 25vh;
    /* min-height: 15vh; */
    border: 1px solid #000000;
    border-radius: 5px;
    color: rgba(6, 6, 6, 0.886);
    background-color: rgb(62, 230, 149);
    transition: color 0.5s ease, transform 0.3s ease ,text-decoration-color 0.5s ease,background-color 0.5s ease; 
  }

  .experience-node:hover {
    color: rgba(255, 255, 0, 0.886);
    background-color: rgb(0, 0, 0);
    transform: translate(0px, -5px);
    transform: scale(1.1);
    font-weight: 800;
  }
  
  .company-name {
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    min-height: 40px;
  }
  
  .job-title {
    font-style: italic;
    margin-bottom: 0px;
    font-size: x-small;
    text-align: center;

  }


}